import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

import p1 from "../../assets/images/play/1.png";
import p2 from "../../assets/images/play/2.png";
import p25 from "../../assets/images/play/2.5.png";
import p3 from "../../assets/images/play/3.png";
import p4 from "../../assets/images/play/4.png";

import b1 from "../../assets/images/buy/1.png";
import b2 from "../../assets/images/buy/2.png";
import b3 from "../../assets/images/buy/3.png";
import b4 from "../../assets/images/buy/4.png";
import b5 from "../../assets/images/buy/5.png";

const data = {
    rules: {
        title: "Правила",
        elements: [
            {
                title: "Важное!",
                content:
                    <>
                        Эти правила — лишь пример нарушений и наказаний.<br/><br/>
                        𝟎.𝟏 Пользователь принимает и обязуется соблюдать данный свод правил с момента первого
                        использования любого ресурса проекта (игровой сервер, любой сайт и приложение).<br/>
                        𝟎.𝟐 Модерация имеет право наказывать за не указанные здесь правила, если они существуют,
                        но ещё не были опубликованы.<br/>
                        𝟎.𝟑 Модерация имеет право устанавливать время наказания, исходя из своего личного мнения
                        и своих собственных убеждений, руководствуясь имеющимся списком правил и их
                        ограничениями.
                        <br/><br/>
                        В случае несогласия с действиями модерации можно подать жалобу на <a
                        href="https://forum.diamondworld.pro" target="_blank">форуме</a>.<br/>
                        При обжаловании наказания нужно указывать его ID (строка вида #𝟏𝟐𝟑abc).
                        <br/><br/>
                        𝟎.𝟒 В случае многочисленных/многократных нарушений правил проекта со стороны
                        пользователя, куратор модерации и его помощник имеют право применить более строгое
                        наказание, чем указано в правилах, вплоть до блокировки аккаунта.<br/><br/>

                        <b>Для того, чтобы не получить наказание, достаточно просто общаться как адекватный
                            человек, никого не обижая своими действиями.</b>
                    </>
            },
            {
                title: "𝟏. Общие правила",
                content:
                    <>
                        𝟏.𝟏 Администрация проекта имеет право изменять настоящие правила с оповещением об этом
                        игроков в <a href="https://vk.com/diamondworld_staff">группе персонала</a>.<br/>
                        𝟏.𝟐 Попытка обхода правил проекта наказывается в соответствии с нарушенным пунктом.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;𝟏.𝟐.𝟏 Нарушение правил проекта на любом второстепенном аккаунте
                        (аккаунт, созданный позже первого аккаунта на проекте) наказывается в отношении всех
                        аккаунтов игрока.<br/>
                        𝟏.𝟑 В случае возврата платежа при помощи средств платежной системы аккаунт игрока будет
                        заблокирован навсегда.<br/>
                        𝟏.𝟒 Большинство вещей на проекте не подлежат обмену или возврату, в том числе при
                        непонимании игроком принципов их работы или неполадках на проекте.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;𝟏.𝟒.𝟏 Подлежат возврату в случае некорректной выдачи в связи с
                        неполадками:<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Привилегии, вещи и услуги, приобретённые
                        через меню "/donate".<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Валюта личного счёта, приобретённая
                        через сайт "diamondworld.pro".<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;𝟏.𝟒.𝟐 <b>Не</b> подлежат возврату в любом из случаев:<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Прогресс на режимах и статистика.<br/>
                        𝟏.𝟓 Если аккаунт был заблокирован или обнулен вследствие серьезного багоюза - вещи
                        возвращены не будут.<br/>
                        𝟏.𝟔 Игрок имеет право просить переноса основного аккаунта, который не имеет активную
                        блокировку, на другой ник, без сохранения статистики, привилегий, вещей и услуг,
                        приобретённых через меню "/donate", а также прогресса на режимах.<br/>
                        𝟏.𝟕 Если к аккаунту получили доступ третьи лица, то ответственность за него полностью
                        несет владелец аккаунта.<br/>
                        𝟏.𝟖 Восстановление аккаунта после взлома является платной услугой, которая оказывается
                        со стороны руководства проекта.<br/>
                        𝟏.𝟗 Разбан аккаунта пользователя является платной услугой, которая оказывается по
                        усмотрению руководства проекта, а цена является персональным пунктом данной услуги для
                        каждого пользователя.<br/>
                        𝟏.𝟏𝟎 Сотрудник проекта <b>вправе</b> давать предупреждение перед выдачей наказания.<br/>
                        𝟏.𝟏𝟏 Каждый сотрудник проекта обязан вести себя адекватно и соблюдать правила
                        проекта.<br/>
                        𝟏.𝟏𝟐 Сотрудники проекта не обязаны понимать шутки и будут выдавать за них полноразмерное
                        наказание, если шутка нарушает любое из правил проекта.<br/>
                        𝟏.𝟏𝟑 Действия сотрудников и работоспособность команды проекта регулируется регламентом,
                        который устанавливается Владельцем проекта и куратором соответствующего
                        подразделения.<br/>
                        𝟏.𝟏𝟒 На любые некорректные действия персонала проекта можно подать апелляцию или жалобу.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;* Апелляции на решение сотрудника можно подать только в течение
                        15 суток с момента выдачи наказания.<br/>
                        𝟏.𝟏𝟓 Администрация проекта вправе устанавливать любые ограничения (мут, бан и т.п.) по
                        любым адекватным причинам и на любое время.<br/>
                        𝟏.𝟏𝟔 Администрация и сотрудники проекта имеют право, при наличии веских причин, изменять
                        наказание за нарушения со стороны пользователя.<br/>
                    </>
            },
            {
                title: "𝟐. Правила общения на проекте.",
                content:
                    <>
                        <small>За нарушение пунктов 𝟐.𝟏-𝟐.𝟏𝟕 в личных сообщениях мут не дается.</small><br/><br/>
                        𝟐.𝟏 Флуд в любом виде:<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;𝟐.𝟏.𝟏 Флуд сообщениями (от 3-х однотипных сообщений в течение 2
                        минут).<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка чата на 𝟏𝟓 минут.<br/>

                        &nbsp;&nbsp;&nbsp;&nbsp;𝟐.𝟏.𝟐 Флуд символами (от 10 символов).<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка чата на 𝟏𝟓 минут.<br/>

                        &nbsp;&nbsp;&nbsp;&nbsp;𝟐.𝟏.𝟑 Организация флуда.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка чата на 𝟑𝟎 минут.<br/><br/>

                        𝟐.𝟐 Спам (в т.ч. торговля в обычном чате).<br/>
                        Наказание: блокировка чата на 𝟑𝟎 минут.<br/><br/>

                        𝟐.𝟑 Капс (50% сообщения большими буквами, от 8 букв).<br/>
                        Наказание: блокировка чата на 𝟏𝟓 минут.<br/><br/>

                        𝟐.𝟒 Выпрашивание каких-либо материальных ресурсов.<br/>
                        Наказание: блокировка чата на 𝟏𝟓 минут.<br/><br/>

                        𝟐.𝟓 Мат, нецензурная лексика, завуалированный мат.<br/>
                        Наказание: блокировка чата на 𝟏 час.<br/><br/>

                        𝟐.𝟔 Неадекватное, токсичное и аморальное поведение.<br/>
                        Наказание: блокировка чата на 𝟐 часа.<br/><br/>

                        𝟐.𝟕 Оскорбление игрока либо групп лиц.<br/>
                        Наказание: блокировка чата на 𝟐 часа.<br/><br/>

                        𝟐.𝟖 Упоминание родных в негативной форме.<br/>
                        Наказание: блокировка чата на 𝟔 часов.<br/><br/>

                        𝟐.𝟗 Оскорбление родных.<br/>
                        Наказание: блокировка чата на 𝟏𝟎 часов.<br/><br/>

                        𝟐.𝟏𝟎 Оскорбление сотрудников и проекта:<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;𝟐.𝟏𝟎.𝟏 Оскорбление персонала.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка чата на 𝟏𝟐 часов.<br/>

                        &nbsp;&nbsp;&nbsp;&nbsp;𝟐.𝟏𝟎.𝟐 Оскорбление проекта.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка чата на 𝟏 день.<br/>

                        &nbsp;&nbsp;&nbsp;&nbsp;𝟐.𝟏𝟎.𝟑 Оскорбление администрации.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка чата на 𝟏 день.<br/><br/>

                        𝟐.𝟏𝟏 Обман или введение в заблуждение руководства проекта.<br/>
                        Наказание: срок блокировки аккаунта устанавливается администрацией.<br/><br/>

                        𝟐.𝟏𝟐 Разжигание или участие в конфликте на политические, религиозные, межнациональные и иные
                        темы.<br/>
                        Наказание: блокировка чата на 𝟓 часов.<br/><br/>

                        𝟐.𝟏𝟑 Пропаганда фашизма, нацизма, экстремизма, казино, употребления алкоголя и наркотиков.<br/>
                        Наказание: блокировка чата на 𝟔 часов.<br/><br/>

                        𝟐.𝟏𝟒 Троллинг.<br/>
                        Наказание: блокировка чата на 𝟏 час.<br/><br/>

                        𝟐.𝟏𝟓 Провокация на нарушение правил.<br/>
                        Наказание: блокировка чата на 𝟒 часа.<br/><br/>

                        𝟐.𝟏𝟔 Распространение заведомо ложной информации с целью дезинформации пользователей,
                        клевета.<br/>
                        Наказание: блокировка чата на 𝟏 час.<br/><br/>

                        𝟐.𝟏𝟕 Рекламирование сторонних ресурсов:<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;𝟐.𝟏𝟕.𝟏 Упоминание названия сторонних ресурсов.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка чата на 𝟔 часов.<br/>

                        &nbsp;&nbsp;&nbsp;&nbsp;𝟐.𝟏𝟕.𝟐 Ссылка на сторонний ресурс.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка чата на 𝟏 день / блокировка аккаунта от 𝟏 дня до
                        вечности.<br/><br/>

                        𝟐.𝟏𝟖 Выдача себя за персонал или администрацию проекта с целью получения выгоды или обмана
                        пользователей.<br/>
                        Наказание: блокировка аккаунта от 𝟏 дня до вечности, срок устанавливается Руководителем отдела
                        Модерации.<br/><br/>

                        𝟐.𝟏𝟗 Распространение багов, дюпов, различных уязвимостей и других недоработок сервера.<br/>
                        Наказание: блокировка аккаунта от 𝟏 дня до вечности, срок устанавливается
                        администрацией.<br/><br/>

                        𝟐.𝟐𝟎 Деанонимизация пользователей в виде распространения персональных данных, данных
                        родственников IP адресов, адреса проживания и прочее.<br/>
                        Наказание: блокировка аккаунта на 90 дней.<br/><br/>

                        𝟐.𝟐𝟏 Многочисленное нарушение правил проекта:<br/>
                        * наказание может быть выдано, если пользователь проекта получил определённое количество<br/>
                        наказаний за период 60 дней<br/>
                        10 мутов – блокировка чата на 𝟏 день.<br/>
                        20 мутов – блокировка чата на 𝟐 дня.<br/>
                        30 мутов – блокировка чата на 𝟒 дня.<br/>
                        40 мутов – блокировка аккаунта на 𝟏 день.<br/>
                    </>
            },
            {
                title: "𝟑. Внутриигровые правила.",
                content:
                    <>
                        * Нарушение правил проекта на любом второстепенном аккаунте (аккаунт, созданный позже
                        первого аккаунта на проекте) наказывается в отношении всех аккаунтов игрока.<br/>
                        <br/>
                        𝟑.𝟏 Использование любых модификаций клиента, которые дают преимущество над игроками (читы).<br/>
                        Наказание: блокировка аккаунта на 𝟏 год, с обнулением прогресса на режиме.<br/><br/>

                        𝟑.𝟐 Хранение сторонних модификаций (читы и запрещенные моды).<br/>
                        Наказание: блокировка аккаунта на 𝟏𝟒 дней.<br/><br/>

                        𝟑.𝟑 Отказ, игнорирование, препятствие проведению проверки, выход с сервера во время проверки,
                        неадекватное поведение.<br/>
                        Наказание: блокировка аккаунта на 𝟏𝟖𝟎 дней.<br/><br/>

                        𝟑.𝟒 Передача аккаунта.<br/>
                        Наказание: блокировка аккаунта на 𝟏 год.<br/><br/>

                        𝟑.𝟓 Покупка / продажа чего-либо за реальную валюту.<br/>
                        Наказание: блокировка аккаунта на 𝟏 год с обнулением аккаунта на усмотрение администрации.<br/><br/>

                        𝟑.𝟔 Обман игроков на донат-валюту (ЛК).<br/>
                        Наказание: блокировка аккаунта на 𝟏 год, с конфискацией предметов или средств.<br/><br/>

                        𝟑.𝟕 Использование игровых багов, дюпов, различных уязвимостей и других недоработок сервера
                        (наказание зависит от тяжести нарушения).<br/>
                        Наказание: блокировка аккаунта от 𝟏 дня до вечности, срок устанавливается
                        администрацией.<br/><br/>

                        𝟑.𝟖 Попытка дестабилизировать работу сервера, умышленная поломка сервера.<br/>
                        Наказание: блокировка аккаунта от 𝟏 дня до вечности, срок устанавливается
                        администрацией.<br/><br/>

                        𝟑.𝟗 Некорректный никнейм, нарушающий правила проекта.<br/>
                        Наказание: блокировка аккаунта навсегда.<br/><br/>

                        𝟑.𝟏𝟎 Некорректный скин / плащ, нарушающий правила проекта или содержащий в себе нецензурные
                        символы.<br/>
                        Наказание: предупреждение (кик с сервера), далее блокировка аккаунта на 𝟐 часа.<br/><br/>

                        𝟑.𝟏𝟏 Использование функционала клана для нарушения правил проекта.<br/>
                        Наказание: предупреждение (кик с сервера), далее блокировка аккаунта главы клана на 1 день.<br/><br/>

                        𝟑.𝟏𝟐 Мультиаккаунт (создание более одного аккаунта на сервере).<br/>
                        Наказание: блокировка аккаунта навсегда (кроме основного).<br/>

                        &nbsp;&nbsp;&nbsp;&nbsp;𝟑.𝟏𝟐.𝟏 Неоднократное нарушение правила “Мультиаккаунт”.<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Наказание: блокировка основного аккаунта на 𝟏𝟒 дней, с обнулением
                        аккаунта на усмотрение администрации<br/><br/>

                        𝟑.𝟏𝟑 Взлом аккаунта пользователя.<br/>
                        Наказание: блокировка аккаунта взломщика навсегда.<br/><br/>

                        𝟑.𝟏𝟒 Обход наказаний любыми способами (мут).<br/>
                        Наказание: предупреждение (кик с сервера), далее блокировка аккаунта на время, выданное за
                        блокировку чата.<br/><br/>

                    </>
            },
            {
                title: "𝟒. Разрешенные и запрещенные модификации",
                content:
                    <>
                        Разрешенные<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;MiniMap<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Replay Mod<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;PVP Indicator<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Schematica<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Gamma<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Auto Sprint<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Command Macros<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;EvoPlus<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;FrogHelper<br/>
                        <br/>
                        Запрещенные<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Любые виды читов<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Free Cam<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;AFK Bots<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Trace<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;AutoTool<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;AutoSwitch<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Macros \ Clickers \ .ahk для minecraft<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;TickrateChanger<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;No Mining Delay и другие программы для устранение задержки
                        копания<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;.bat и подобные программы для направленной чистки пк от читов \
                        кликеров или получения преимущества в игре<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;FlightAddon<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;HeliosAddon<br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Неутвержденные аналоги evoplus<br/>
                        <br/>
                        Если какого-то мода нет в этом списке, то это не значит, что он разрешен, лучше уточните
                        это в поддержке<br/>
                    </>
            },
            {
                title: "𝟓. Правила группы ВКонтакте.",
                content:
                    <>
                        𝟓.𝟏 Мат — удаление комментария.<br/>
                        𝟓.𝟏.𝟏 Оскорбление и агрессивное поведение — удаление комментария.<br/><br/>
                        𝟓.𝟐 Рекламирование чего-либо — удаление комментария и черный список.<br/>
                        𝟓.𝟐.𝟏 Упоминание сторонних проектов — удаление комментария.
                    </>
            }
        ]
    },
    faq: {
        title: "Поддержка",
        elements: [
            {
                title: "Проблемы с ресурспаком, что мне делать?",
                content:
                    <>
                        Создайте на <a href={"https://forum.diamondworld.pro"}>форуме</a> тему в разделе
                        "Прошения к Администрации", выбрав тип обращения "Отключение автоматической загрузки
                        ресурспака".<br/><br/>
                        Заполните форму, указав максимальную возможную информацию!
                    </>
            },
            {
                title: "Как пополнить баланс с Украины?",
                content:
                    <>
                        Для пополнения баланса с Украины, необходимо написать нашему официальному боту в <a
                        href={"t.me/paymentdw"}>Telegram</a>.<br/>
                        Заполните все необходимые данные и следуйте инструкциям бота.
                    </>
            },
            {
                title: "Как пополнить баланс из-за рубежа?",
                content:
                    <>
                        К сожалению, из-за санкций, пополнение счёта из-за рубежа временно недоступно. О
                        возобновлении приёма платежей мы сообщим в наших социальных сетях.
                    </>
            },
            {
                title: "Пополнил баланс, но ЛК не пришли, что мне делать?",
                content:
                    <>
                        Возможно, на данный момент у нашей платёжной системы произошёл сбой. Подождите 1-2 часа
                        и если средства на счёт вам не поступят, то обратитесь на форум в раздел <a
                        href={"https://forum.diamondworld.pro/forums/donate/"}>"Проблема с платежами"</a>.<br/>
                    </>
            },
            {
                title: "Отправил ЛК не на тот ник, куда мне обратиться?",
                content:
                    <>
                        Если вы случайно пополнили счёт на неправильный никнейм, то вам следует обратиться в
                        раздел на форуме <a href={"https://forum.diamondworld.pro/forums/donate/"}>"Проблема с
                        платежами"</a><br/><br/>
                        Однако, обратите внимание, что мы не гарантируем возвращения средств на ваш аккаунт, в
                        том числе если игрок уже потратил все средства!
                    </>
            },
            {
                title: "Я нашёл баг, куда мне обратиться?",
                content:
                    <>
                        Если вы нашли баг, то вам следует обратиться на форум в раздел <a
                        href={"https://forum.diamondworld.pro/forums/bugs/"}>"Баги и недоработки сервера"</a>.
                        Пожалуйста, опишите найденный баг как можно подробнее!
                    </>
            },
            {
                title: "Забыл пароль, как мне его восстановить?",
                content:
                    <>
                        Если вы привязывали к своему игровому аккаунту почту, то воспользуйтесь командой /email
                        recovery &lt;почта&gt;. затем перейдите на вашу электронную почту и следуйте дальнейшим
                        инструкциям.<br/>
                        Если же вы не привязывали почту к своему аккаунту, то обратитесь в раздел
                        "Восстановление аккаунта" на <a href={"https://forum.diamondworld.pro"}>форуме</a>.
                        Обратите внимание, что вам нужно будет доказать, что вы являетесь владельцем аккаунта!
                    </>
            },
            {
                title: "Не могу зайти на аккаунт \"Игрок с вашим ником уже на сервере\", что делать?",
                content:
                    <>
                        Сохраняйте спокойствие, вероятнее всего, вас никто не взломал, а произошёл сбой сервера!
                        Обратитесь в службу поддержки, указав ваш никнейм и причину обращения (Игрок с вашим
                        ником уже на сервере). Агент службы поддержки поможет вам решить проблему или подскажет,
                        куда обратится дальше.
                    </>
            },
            {
                title: "Где и как купить разбан?",
                content:
                    <>
                        Купить разбан аккаунта можно на форуме в разделе <a
                        href={"https://forum.diamondworld.pro/forums/unban/"}>"Покупка разблокировки"</a>.
                        Заполните необходимую анкету и ожидайте выставления счёта Менеджером.
                    </>
            },
            {
                title: "Я считаю, что меня наказали неправильно, куда мне обратится?",
                content:
                    <>
                        Если вы считаете, что получили наказание несправедливо, то обратитесь на форум в
                        раздел <a href={"https://forum.diamondworld.pro/forums/appeal/"}>"Жалобы на сотрудников
                        проекта, апелляции"</a><br/><br/>
                        Заполните анкету и обязательно укажите ID наказания. ID можно найти в сообщении о
                        наказании, это цифры после символа #.
                    </>
            },
            {
                title: "Как мне сменить пароль на аккаунте?",
                content:
                    <>
                        Чтобы сменить пароль на сервере, необходимо зайти на сервер и прописать команду
                        /changepassword &lt;старый_пароль&gt; &lt;новый_пароль&gt;.
                    </>
            },
            {
                title: "Когда вайп?",
                content:
                    <>
                        Информация о вайпе доступна только Администрации проекта, и никто другой не знает о дате
                        вайпа! Мы заранее уведомляем игроков о вайпах в наших социальных сетях.
                    </>
            },
            {
                title: "У меня появилась идея, куда я могу её написать?",
                content:
                    <>
                        Если у вас есть идея, вы можете поделиться ею на форуме в разделе <a
                        href={"https://forum.diamondworld.pro/forums/ideas-old/"}>"Предложения и идеи"</a>,
                        выбрав нужный режим. Постарайтесь подробнее описать вашу идею, это повысит шансы на то,
                        что она будет одобрена!
                    </>
            },
            {
                title: "Хочу вступить в команду проекта, как мне это сделать?",
                content:
                    <>
                        Если у вас есть желание стать частью нашей команды, то вы можете подать заявку на любую
                        должность в нашем проекте. Узнать о вакансиях можно на нашем <a
                        href={"https://forum.diamondworld.pro"}>форуме</a>.
                    </>
            },
        ]
    },
    play: {
        title: "Как играть?",
        elements: [
            {
                title: "𝟏. Открой игру.",
                content:
                    <>
                        Для игры на сервере обязательна версия 𝟏.𝟏𝟗.𝟒 или выше, учти это!<br/><br/>
                        𝟏.𝟏 Нажми "Сетевая игра" или же "Multiplayer".<br/><br/>
                        <div><img src={p1} alt="Выбор режима игры"/></div>
                    </>
            },
            {
                title: "𝟐. Добавь сервер.",
                content:
                    <>
                        𝟐.𝟏 Нажми "Добавить" или же "Add server".<br/><br/>
                        <div><img src={p2} alt="Список серверов"/></div>
                        <br/><br/>

                        𝟐.𝟐 Введи IP проекта: mc.diamondworld.pro<br/><br/>
                        <div><img src={p3} alt="Добавление нового сервера"/></div>
                        <br/><br/>

                        𝟐.𝟑 Обязательно укажи "Наборы ресурсов: Разрешены" или же "Server Resource Packs:
                        Enabled".<br/><br/>

                        𝟐.𝟑 Нажми "Готово" или же "Done".
                    </>
            },
            {
                title: "𝟐.𝟓 Уже добавил, но не могу зайти :(",
                content:
                    <>
                        𝟐.𝟓.𝟏 Проверь, точно ли разрешено использование ресурсов, нажав на сервер в списке
                        серверов и кнопку "Настроить".<br/><br/>
                        <div><img src={p25} alt="Настройка ресурспака"/></div>
                        <br/><br/>

                        𝟐.𝟓.𝟐 Обязательно укажи "Наборы ресурсов: Разрешены" или же "Server Resource Packs:
                        Enabled".<br/><br/>

                        𝟐.𝟓.𝟑 Нажми "Готово" или же "Done".<br/><br/>

                        Если подключиться так и не получилось - напиши в техническую поддержку (пульсирующая
                        кнопочка внизу слева) и тебе обязательно помогут :)<br/>
                        * Техническая поддержка работает с 𝟏𝟎:𝟎𝟎 до 𝟐𝟐:𝟎𝟎 по МСК, в остальное время кнопка
                        скрыта.
                    </>
            },
            {
                title: "𝟑. Заходи!",
                content:
                    <>
                        Новые развлечения и друзья уже ждут тебя! :з<br/><br/>
                        <div><img src={p4} alt="Сервер DiamondWorld"/></div>
                        <br/><br/>
                    </>
            }
        ]
    },
    manual: {
        title: "Как пополнить баланс?",
        elements: [
            {
                title: "𝟏. Ввод данных.",
                content:
                    <>
                        𝟏.𝟏 Введи ник, сумму платежа и промокод, если знаешь подобный.<br/><br/>
                        <div><img src={b1} alt="Окно ввода данных"/></div>
                        <br/><br/>
                        𝟏.𝟐 Нажми "Пополнить баланс".
                    </>
            },
            {
                title: "𝟐. Способ оплаты.",
                content:
                    <>
                        𝟐.𝟏 Выбери удобный тебе способ оплаты.<br/><br/>
                        <div><img src={b2} alt="Способы оплаты"/></div>
                        <br/><br/>
                    </>
            },
            {
                title: "𝟑. Снова ввод данных...",
                content:
                    <>
                        𝟑.𝟏 Введи необходимые для покупки данные.<br/><br/>
                        <div><img src={b3} alt="Ввод данных для оплаты"/></div>
                        <br/><br/>
                    </>
            },
            {
                title: "𝟒. Оплата.",
                content:
                    <>
                        𝟒.𝟏 Оплати свои покупки :)<br/><br/>
                        <div><img src={b4} alt="Окно успешной оплаты"/></div>
                        <br/><br/>
                    </>
            },
            {
                title: "𝟓. Готово!",
                content:
                    <>
                        𝟓.𝟏 Средства на счёт поступят незамедлительно, можно сразу купить необходимое и
                        наслаждаться игрой!<br/><br/>
                        <div><img src={b5} alt="Меню привилегий на сервере"/></div>
                        <br/><br/>
                    </>
            }
        ]
    }
};

const Elements = ({category, additional}) => {
    return <div className="manual">
        {
            additional ? <>
                <ul className="collapsible" key={Math.random()}>
                    <li>
                        {additional}
                    </li>
                </ul>
                <br/>
            </> : <></>
        }
        {data[category]?.elements?.map(elem =>
            <ul className="collapsible" key={Math.random()}>
                <li>
                    <div className="collapsible-header">
                        {elem.title}
                        <img className="menu-pointer"
                             src="https://img.icons8.com/metro/26/000000/mouse-left-click.png"
                             alt="Иконка клика мыши"/>
                    </div>
                    <div className="collapsible-body">{elem.content}</div>
                </li>
            </ul>
        )}
    </div>
}
export const ManualComponent = ({history, match}) => {
    const [category, setCategory] = useState({});

    useEffect(() => {
        if (!data[match.params.category]) return history.push("/");

        setCategory(match.params.category);

        setTimeout(() =>
                window.M.Collapsible.init(document.querySelectorAll(".collapsible"), {})
            , 500);
    }, [data, match.params.category, history]);

    return (
        <>
            <Helmet title={`${data[category]?.title} < DiamondWorld`}/>
            {category === 'faq' ?
                <>

                    <Elements category={category} additional={
                        <div className="collapsible-header">
                            Как обратится в службы поддержки?<br/><br/>

                            1. Нажмите на облачко в правом нижнем углу.<br/><br/>

                            2. Введите ваш игровой никнейм, который вы используете на сервере.<br/><br/>

                            3. Воспользуйтесь встроенным ботом для решение вашей проблемы. Если бот не помог или не
                            ответил на ваш вопрос, нажмите кнопку вызова агента. Агент службы поддержки подключится к
                            чату и поможет решить вашу проблему.<br/><br/>

                            График работы службы поддержки: с 10:00 до 22:00 МСК!
                        </div>
                    }/>
                </> : <Elements category={category}/>
            }

        </>
    );
}
